<template>
  <CommonPages id="home" :class="['home', `theme-${domainConfig?.designType || 'default'}`]">
    <div class="banner-row home m_padding">
      <div class="banner-head">
        {{ domainConfig?.['home']?.title }}
      </div>
      <div class="banner-content">
        {{ domainConfig?.['home']?.des }}
      </div>
      <div v-if="author" class="writer-tag">
        Writer: {{ author }}
      </div>
    </div>

    <AdComponent v-if="$global.isObjectNotEmpty(adsensConfig) && adsensConfig?.home_1"
      ref="ads-home-insert-1" class="ads-insert-max pc_padding  m-t-40" :ads="adsensConfig?.home_1"
      :showDebug="showDebug" />

    <div class="bible-book-container pc_padding m_padding">
      <div v-for="(book,i) in books" :key="i" class="bible-book-row">
        <div class="bible-book-title" v-html="book.part" />
        <div class="bible-book-list">
          <template v-for="(chapters,j) in book.chapters">
            <div :key="`chapter-${i}-${j}`" class="bible-book-item cursor"
              @click="chooseBook(book.part, chapters)">
              {{ chapters }}
            </div>
            <!-- 在全局第10个位置插入广告 -->
            <AdComponent
              v-if="getTotalIndex(i, j) === 9 && deviceType == 'mobile' && $global.isObjectNotEmpty(adsensConfig) && adsensConfig?.home_insert"
              :ref="'ads-home-new-insert'" class="ads-insert-max ads-insert"
              :ads="adsensConfig?.home_insert" :showDebug="showDebug" />
          </template>
        </div>
      </div>
    </div>

    <AdComponent
      v-if="deviceType == 'mobile' && $global.isObjectNotEmpty(adsensConfig) && adsensConfig?.home_2"
      ref="ads-home-insert-1" class="ads-insert-max pc_padding  m-t-40" :ads="adsensConfig?.home_2"
      :showDebug="showDebug" />

    <div class="welcome-row  pc_padding m_set_width">
      <div class="welcome-title" v-html="domainConfig?.['what']?.title" />
      <div class="welcome-content" v-html="domainConfig?.['what']?.des" />
    </div>
    <div class="how-to-row  pc_padding m_set_width">
      <div class="how-to-title" v-html="domainConfig?.['how']?.title" />
      <div class="how-to-content" v-html="domainConfig?.['how']?.des" />
    </div>

    <div class="blog-list-container ">
      <div class="blog-list-title">
        Recommend Reading
      </div>
      <div class="blog-card-row">
        <div v-for="(data, i) in card" :key="i" class="blog-card-item cursor"
          @click="goto(data.routename)">
          <img :src="data.img" class="blog-card-image" :alt="data.title">
          <div class="blog-card-content">
            <div class="blog-card-title">
              {{ data.title }}
            </div>
            <div class="blog-card-desc">
              {{ data.desc }}
            </div>
          </div>
          <div class="blog-card-btn">
            Read More <img src="@/assets/readmore.png" alt="">
          </div>
        </div>
      </div>
    </div>

    <a href="/blogs">
      <div class="blog-all-btn cursor">
        View All
      </div>
    </a>
  </CommonPages>
</template>

<script>
import common from '@/mixin/common.js';
import { mapGetters } from 'vuex';
import AdComponent from '@/pages/components/AdComponent.vue'
import CommonPages from '@/pages/components/page_common.vue'
import '@/css/home.scss';

export default {
  name: 'Home',
  components: {
    AdComponent,
    CommonPages,
  },
  mixins: [common],
  data () {
    return {
      hasInitBooksFromServer: false,
      abbrev: null,
      books: [],
      chapterContent: null,
      versesContent: null,
      currentChapter: null,
      card: [],
      author: null,
      movieList: [],
      hasNext: true,
      page: 1,
      observedAds: null
    };
  },
  computed: {
    title () {
      return `Home | ${this.domainConfig?.['meta']?.title || 'Default Title'}`
    },
    description () {
      return `${this.domainConfig?.['meta']?.des || 'Default des'}`
    },
    oldTestamentBooks () {
      return this.books.filter(book => book.testament === 'Old Testament');
    },
    newTestamentBooks () {
      return this.books.filter(book => book.testament === 'New Testament');
    },
    ...mapGetters([
      "adsensConfig",
      'adPosConfig',
    ]),

  },
  metaInfo () {
    return {
      title: this.title,
      meta: [
        {
          name: 'description',
          content: this.description,
        },
        {
          property: 'og:title',
          content: this.title,
        },
        {
          property: 'og:description',
          content: this.description,
        },
      ],
    };
  },
  created () {
    // 优先从 store 获取数据
    const storeParts = this.$store.getters.getParts
    const storeAuthor = this.$store.getters.getAuthor

    if (storeParts?.length > 0 && storeAuthor) {
      // console.log('Using data from store in created:', { parts: storeParts, author: storeAuthor })
      this.books = storeParts
      this.author = storeAuthor
      this.hasInitBooksFromServer = true
    }

    // 初始化推荐博客列表
    const type = this.domainConfig?.Type
    // console.log('Current type:', type)
    if (type) {
      this.card = [1, 2, 3, 4].map(num => ({
        img: require(`@/assets/blogs/${type}/${num}-1.jpg`),
        title: this.getBlogConfig(num).title,
        desc: this.getBlogConfig(num).desc,
        routename: `blog-${num}`
      }))
    }
  },
  async activated () {
    console.log(`${this.$options.name} Component activated-----------`);
  },
  async mounted () {
    console.log(`-------------------${this.$options.name} mounted`)

    // 检查服务端数据是否有效
    const hasValidServerData = this.$root.$options?.context?.hasValidServerData
    console.log('Server data validation:', hasValidServerData)

    // 只有在 store 中没有数据时才调用 initPart
    if (!this.hasInitBooksFromServer || !hasValidServerData) {
      try {
        await this.initPart();
      } catch (err) {
        if (err.message !== 'cancel') {  // 忽略取消的请求错误
          console.error('Failed to fetch data:', err);
        }
      }
    }

    this.loadAdSenseScript();  // 重新加载广告脚本
    if (typeof window !== 'undefined') {
      this.initAdTracking(); //客户端交互
    }
  },
  deactivated () {
    console.log(`${this.$options.name} Component deactivated-----------`);
  },
  beforeDestroy () {
    console.log(`-------------------${this.$options.name} beforeDestroy`);
    // 滚动监听移除示例
    // window.removeEventListener('scroll', this.onScroll);
    if (this.checkInterval) {
      clearInterval(this.checkInterval); //客户端交互移除
    }
    if (this.visibilityChangeHandler) {
      document.removeEventListener('visibilitychange', this.visibilityChangeHandler);//客户端交互移除
    }
  },
  methods: {
    //页面方法 ...
    chooseBook (part, chapters) {
      // 格式化路径参数，将空格转换为连字符
      const formattedPart = this.formatName(part);
      const formattedChapters = this.formatName(chapters);

      // 获取当前路由参数
      const currentParams = this.$router.currentRoute.params;
      const currentQuery = this.$route.query;

      // 构建URL
      const channel = currentParams.channel ? `/${currentParams.channel}` : '';
      const queryString = new URLSearchParams(currentQuery).toString();
      const fullQueryString = queryString ? `?${queryString}` : '';

      // 跳转到新页面
      window.location.href = `${channel}/${formattedPart}/${formattedChapters}${fullQueryString}`;
    },
    formatName (name) {
      return name
        .split('-')
        .join(' ');
    },
    transformName (name) {
      return name
        .split(' ')
        .join('-');
    },
    async initPart () {
      // 先从 Vuex store 中获取数据
      const storeParts = this.$store.getters.getParts
      const storeAuthor = this.$store.getters.getAuthor

      // 如果 store 中有数据，直接使用
      if (storeParts?.length > 0 && storeAuthor) {
        console.log('Using data from store:', { parts: storeParts, author: storeAuthor })
        this.books = storeParts
        this.author = storeAuthor
        return
      }

      console.log('Fetching data from API')
      const params = {
        type: this.domainConfig?.Type || 'shakespeare',
        pageNum: 1,
        pageSize: 100,
      }

      try {
        const res = await this.$global.server.initPart(params)
        console.log('API response:', res)

        if (res?.data) {
          this.books = res.data.partsAndChapters
          this.author = res.data.author

          // 将数据保存到 store
          this.$store.commit('SET_PARTS', res.data.partsAndChapters)
          this.$store.commit('SET_AUTHOR', res.data.author)
        }
      } catch (err) {
        console.error('Failed to fetch data:', err)
      }
    },
    async fetchChapter () {
      try {
        const params = {
          abbrev: this.abbrev,
        };

        const res = await this.$global.server.fetchChapter(params);
        console.log(res)
        this.chapterContent = res.data.chapters;
        this.currentChapter = null;
      } catch (err) {
        console.log(err)
      }
    },
    async selectChapter (chapterNumber) {
      try {
        const params = {
          abbrev: this.abbrev,
          chapter: chapterNumber,
        };

        const res = await this.$global.server.fetchChapter(params);
        console.log(res)
        this.versesContent = res.data.verses;
        this.currentChapter = chapterNumber;
      } catch (err) {
        console.log(err)
      }

    },

    //获取列表 示例
    async getImages () {
      try {
        const params = {
          pageNum: this.page,
          pageSize: 30,
        };

        // 只有当 this.tag 不为 null 时，才添加 tag 字段
        if (this.activeTag !== null) {
          params.tag = this.activeTag;
        }

        const res = await this.$global.server.postFUC(params);
        console.log(res)
        if (res.code == 200) {
          let data = res.data.photos
          this.images.push(...data)
          this.hasNext = res.data.hasNext
          this.page++;
        }
        // 数据更新后，等待 DOM 更新完成再观察一遍广告！
        this.$nextTick(() => {
          this.observeAds();
        });
      } catch (err) {
        console.log(err)
      }
    },
    // 防抖函数 + 滚动
    debounce (func, delay) {
      let timeout;
      return function (...args) {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          func.apply(this, args);
        }, delay);
      };
    },
    onScroll () {
      const documentHeight = document.documentElement.scrollHeight;// 获取文档的总高度
      const scrollTop = window.scrollY || document.documentElement.scrollTop; // 当前滚动的高度
      const windowHeight = window.innerHeight;// 视口的高度
      // 当滚动距离接近底部2000px时触发
      if (scrollTop + windowHeight >= documentHeight - 2000) {
        console.log('加载');
        // this.getImages();
      }
    },
    //广告相关start
    shouldInsertAd (pos, index) {
      // 判断是否应该插入广告的位置
      const position = index + 1;
      const adIndex = this.adPosConfig?.[pos].indexOf(position) //TODO: 修改adPosConfig.home的字段为你设置的

      /// 返回广告在 homeAD 中的索引，如果不在配置中则返回 -1
      return adIndex !== -1 ? adIndex : -1;
    },
    shouldInsertAdDeviece (pos, index) {
      // 判断是否应该插入广告的位置
      const position = index + 1;
      const adIndex = this.deviceType == 'mobile' ? this.adPosConfig?.[pos]?.indexOf(position) : this.adPosConfig?.[`${pos}PC`]?.indexOf(position);

      /// 返回广告在 homeAD 中的索引，如果不在配置中则返回 -1
      return adIndex !== -1 ? adIndex : -1;
    },
    shouldDetailInsertAd (index) {
      // 判断是否应该插入广告的位置
      const position = index + 1;
      const adIndex = this.adPosConfig.detail.indexOf(position);

      /// 返回广告在 homeAD 中的索引，如果不在配置中则返回 -1
      return adIndex !== -1 ? adIndex : -1;
    },
    async loadAdSenseScript () {
      // 检查是否有广告配置
      if (!this.adsensConfig || !this.$global.isObjectNotEmpty(this.adsensConfig)) {
        console.log('No adsense config found');
        return;
      }

      // 先检查广告是否已经加载
      if (window.adsbygoogle && window.adsbygoogle.loaded) {
        console.log("Adsense script already loaded.");
        this.$eventrack('adscript_loaded', 'expose');
        this.observeAds()
        return;
      }

      await this.loadScriptConditionally();
    },
    loadScriptConditionally () {
      // 判断广告脚本URL是否存在
      if (!this.adsensConfig?.scriptUrl) {
        console.log('广告脚本的URL不存在,终止加载广告外链');
        this.$eventrack('no_adscript_config', 'expose');
        return;
      }

      // 检查该脚本是否已经被添加
      const existingScript = document.querySelector(`script[src="${this.adsensConfig?.scriptUrl}"]`);
      if (existingScript) {
        this.$eventrack('adscript_exist', 'expose');
        console.log('脚本已存在，无需重新添加');
        this.observeAds();
        return;
      }

      try {
        console.log('准备插入脚本');
        const script = document.createElement('script');
        script.src = this.adsensConfig?.scriptUrl;
        script.crossOrigin = 'anonymous';
        script.async = true;
        script.onerror = (error) => {
          console.error('Failed to load ad script:', error);
          this.$eventrack('adscript_load_error', 'error');
        };

        const header = document.getElementsByTagName('head')[0];
        header.appendChild(script);

        this.$eventrack('adscript_add_success', 'expose');
        script.onload = this.observeAds.bind(this);
        console.log('脚本插入完成,加载完成,执行加载插入广告及监听操作');
      } catch (error) {
        console.error('Error loading ad script:', error);
        this.$eventrack('adscript_add_error', 'error');
      }
    },
    observeAds () {
      const adsElements = Object.entries(this.$refs)
        .filter(([key]) => key.startsWith('ads-'))
        .flatMap(([, ref]) => ref);
      if (!adsElements.length) {
        console.log('No ad elements to observe');
        return; // 如果没有广告元素，直接返回
      }

      if (!this.observedAds) {
        this.observedAds = new Set();
      }

      const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            // 当广告元素进入可视区域时加载广告
            console.log('Ad element is in view:', entry.target);
            this.loadAdWithDelay(entry.target);
            observer.unobserve(entry.target); // 加载后停止观察该广告元素
          }
        });
      });

      adsElements.forEach(ad => {
        const adElement = ad instanceof HTMLElement ? ad : ad.$el;
        if (adElement && adElement instanceof HTMLElement && !this.observedAds.has(adElement)) {
          observer.observe(adElement);
          this.observedAds.add(adElement);
          console.log('Observing new ad element:', adElement);
          console.log(this.observedAds);
        }
      });

    },
    loadAdWithDelay (adElement) {
      // console.log('7897988979879');
      // console.log(adElement);
      setTimeout(() => {
        this.displayAd(adElement);
      }, 1500);
    },

    async displayAd (adElement) {
      await this.$nextTick();

      if (!window.adsbygoogle || !window.adsbygoogle.loaded) {
        console.log("Adsense script not loaded yet, delaying ad display.");
        setTimeout(() => this.displayAd(adElement), 500); // 延迟再次尝试
        return;
      }

      console.log('Ad element is ready for push:', adElement);
      if (adElement.offsetWidth > 0) {
        console.log('ready to push');
        (window.adsbygoogle = window.adsbygoogle || []).push({});
      } else {
        console.log('Ad element has no size, skipping.');
      }
    },
    //广告相关end

    //跳转
    goto (routername) {
      window.location.href = `/${routername}`
    },

    //客户端交互 1121已优化版本
    initAdTracking () {
      this.activeIframe = null; // 存储最近被点击的 iframe
      this.iframes = new Map(); // 存储已添加的 iframe

      // 监听 iframe 的插入
      this.watchForIframeInsertion();

      // 设置页面可见性变化的处理
      this.setupVisibilityTracking();
    },
    watchForIframeInsertion () {
      const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          mutation.addedNodes.forEach((node) => {
            if (node.tagName === 'IFRAME' && node.closest('ins.adsbygoogle')) {
              this.addIframe(node);
            }
          });
        });
      });

      observer.observe(document.body, { childList: true, subtree: true });
    },
    addIframe (iframeElement) {
      if (this.iframes.has(iframeElement)) {
        return; // 已经添加过，直接返回
      }

      this.iframes.set(iframeElement, {
        hasTracked: false,
        adSlot: iframeElement.closest('ins.adsbygoogle')?.dataset.adSlot || null,
      });

      // 如果还没有启动定时器，启动检查点击的定时器
      if (!this.checkInterval) {
        this.checkInterval = setInterval(this.checkIframeClick, 200);
      }
    },
    checkIframeClick () {
      const activeElement = document.activeElement;
      if (activeElement && this.iframes.has(activeElement)) {
        const iframeData = this.iframes.get(activeElement);
        if (!iframeData.hasTracked) {
          this.handleIframeClick(activeElement);
          iframeData.hasTracked = true;
          this.activeIframe = { element: activeElement, ...iframeData };
        }
      }
    },
    handleIframeClick (iframeElement) {
      console.log('Iframe 点击触发了!', iframeElement);
      const adContainer = iframeElement.closest('ins.adsbygoogle');

      if (adContainer) {
        // 获取父级 ins 元素的边界矩形
        const insRect = adContainer.getBoundingClientRect();

        // 计算父级 ins 元素相对于页面左上角的位置和高度
        const transformData = {
          insX: insRect.left,
          insY: insRect.top,
          insHeight: insRect.height,
          windowWidth: window.innerWidth,
          windowHeight: window.innerHeight,
        };

        // 调用核心功能
        this.$adClick(JSON.stringify(transformData));
        this.trackEvent('ad_iframe_click', 'click', {
          'data-ad-slot': adContainer.dataset.adSlot,
        });
      }
    },
    setupVisibilityTracking () {
      this.visibilityChangeHandler = () => {
        if (document.visibilityState === 'visible') {
          console.log('页面激活状态，返回页面');
          this.activeIframe = null; // 重置最近被点击的 iframe 记录
        } else {
          console.log('页面非激活状态');
          if (this.activeIframe) {
            // 上报逻辑
            this.trackEvent('ad_iframe_click_leave', 'click', {
              'data-ad-slot': this.activeIframe.adSlot,
            });
          }
        }
      };

      document.addEventListener('visibilitychange', this.visibilityChangeHandler);
    },
    trackEvent (eventName, eventValue, dataAttributes) {
      // 事件追踪逻辑
      console.log(`${eventName} with value ${eventValue} on`, dataAttributes);

      // 调用核心功能
      if (typeof this.$eventrack === 'function') {
        this.$eventrack(eventName, eventValue, {
          ...dataAttributes,
        });
      }
    },
    //客户端交互
    getBlogConfig (num) {
      const configs = require('@/data/blogConfigs.json')
      const type = this.domainConfig?.Type || 'shakespeare'
      return configs[type][num] || { title: `Blog ${num}`, desc: 'No description available' }
    },
    // 计算全局索引
    getTotalIndex (bookIndex, chapterIndex) {
      let totalIndex = 0;
      // 计算之前的书籍中的所有章节
      for (let i = 0; i < bookIndex; i++) {
        totalIndex += this.books[i].chapters.length;
      }
      // 加上当前书籍中的章节索引
      totalIndex += chapterIndex;
      return totalIndex;
    },
  }
};
</script>
